"use client"

import * as React from "react"

import { DMIcon, DMIconWrapper, normalizeTestId } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"

import { IconCheck, IconChevronRight, IconSettings } from "@supernovaio/icons"

import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"

import { minWidthVariants } from "../../styling/popovers"
import { getLineClampClassname } from "../DMMenu/utils"

import { VariantProps } from "class-variance-authority"

const DropdownMenu = DropdownMenuPrimitive.Root
const DropdownMenuGroup = DropdownMenuPrimitive.Group
const DropdownMenuPortal = DropdownMenuPrimitive.Portal
const DropdownMenuSub = DropdownMenuPrimitive.Sub
const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup

const DropdownMenuTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger>
>(({ ...props }, ref) => (
  <DropdownMenuPrimitive.Trigger asChild {...props} ref={ref} />
))

DropdownMenuTrigger.displayName = DropdownMenuPrimitive.Trigger.displayName

const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean
    icon?: React.ComponentProps<typeof DMIcon>["svg"]
    dataTestId?: string
  }
>(({ className, inset, children, icon, dataTestId, ...props }, ref) => (
  <DropdownMenuPrimitive.SubTrigger
    ref={ref}
    className={cn(
      "data-[state=open]:bg-neutral focus:bg-neutral focus:text-neutral text-body data-[disabled]:opacity-disabled relative flex min-h-[32px] cursor-pointer select-none items-center rounded px-8 font-semibold outline-none transition-colors data-[disabled]:pointer-events-none",
      inset && "pl-x4",
      className
    )}
    {...props}
    data-test-id={normalizeTestId(dataTestId)}
  >
    {icon && (
      <div className="mr-8">
        <DMIcon size="small" svg={icon} />
      </div>
    )}
    {children}
    <DMIcon className="ml-auto" size="medium" svg={IconChevronRight} />
  </DropdownMenuPrimitive.SubTrigger>
))

DropdownMenuSubTrigger.displayName =
  DropdownMenuPrimitive.SubTrigger.displayName

const DropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent> & {
    isScrollableVertically?: boolean
  } & VariantProps<typeof minWidthVariants>
>(
  (
    {
      className,
      children,
      isScrollableVertically = true,
      size = "small",
      ...props
    },
    ref
  ) => (
    <DropdownMenuPrimitive.SubContent ref={ref} className="z-40" {...props}>
      <div
        className={cn(
          "scrollbar-fancy flex flex-col gap-2 rounded-medium border-neutral-faded bg-elevation-overlay shadow-overlay animate-in data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1 max-h-[calc(var(--radix-popper-available-height,--radix-dropdown-menu-content-available-height)-8px)] overflow-hidden border p-4",
          {
            "overflow-y-auto": isScrollableVertically,
          },
          minWidthVariants({ size }),
          className
        )}
      >
        {children}
      </div>
    </DropdownMenuPrimitive.SubContent>
  )
)

DropdownMenuSubContent.displayName =
  DropdownMenuPrimitive.SubContent.displayName

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> &
    VariantProps<typeof minWidthVariants> & {
      /**
       * Whenever the content should be scrollable. If true then the content will have max available height and scrolls.
       * If false the dropdown content will stretch to the height of all it's elements.
       */
      isScrollable?: boolean
    }
>(
  (
    {
      className,
      sideOffset = 4,
      align = "start",
      size = "small",
      isScrollable,
      ...props
    },
    ref
  ) => (
    <DropdownMenuPrimitive.Portal>
      <DropdownMenuPrimitive.Content
        ref={ref}
        align={align}
        className={cn(
          "rounded-medium border-neutral-faded bg-elevation-overlay text-neutral shadow-overlay animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-40 overflow-hidden border p-4",
          minWidthVariants({ size }),
          isScrollable &&
            "max-h-[calc(var(--radix-popper-available-height)-5px)] overflow-auto scrollbar-fancy",
          className
        )}
        sideOffset={sideOffset}
        hideWhenDetached
        {...props}
      />
    </DropdownMenuPrimitive.Portal>
  )
)

DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  Omit<
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>,
    "disabled"
  > & {
    icon?: React.ComponentProps<typeof DMIcon>["svg"]
    isSelectable?: boolean // should be isAction to be aligned with DMMenu, but we need to refactor this whole thing anyway and this one is clearer
    description?: string
    inset?: boolean
    selected?: boolean
    isDestructive?: boolean
    isDisabled?: boolean
    endSlot?: React.ReactNode
    iconWrapped?: boolean
    dataTestId?: string
  }
>(
  (
    {
      className,
      icon,
      inset,
      isSelectable = false,
      isDisabled,
      isDestructive,
      children,
      selected,
      endSlot,
      iconWrapped,
      description,
      dataTestId,
      ...props
    },
    ref
  ) => (
    <DropdownMenuPrimitive.Item
      ref={ref}
      className={cn(
        "text-body relative flex gap-8 min-h-[32px] cursor-pointer select-none rounded px-8 font-semibold transition-colors",
        "focus:bg-neutral focus:text-neutral outline-none",
        "data-[disabled]:opacity-disabled data-[disabled]:pointer-events-none",
        isDestructive &&
          "data-[highlighted]:bg-critical-faded data-[highlighted]:text-critical",
        inset && "pl-x4",
        isSelectable && !selected && "pl-32", // compensates the space for the icon
        { "items-center": !iconWrapped, "items-start": iconWrapped },
        description && "py-[6px] items-start",
        className
      )}
      disabled={isDisabled}
      data-test-id={normalizeTestId(dataTestId)}
      {...props}
    >
      {selected && (
        <DMIcon
          size="small"
          svg={IconCheck}
          className={description && "mt-2"}
        />
      )}
      {icon &&
        (iconWrapped ? (
          <div className="mt-8">
            <DMIconWrapper>
              <DMIcon size="small" svg={icon} />
            </DMIconWrapper>
          </div>
        ) : (
          <DMIcon
            size="small"
            svg={icon}
            className={cn(description && "mt-2")}
          />
        ))}
      <div className="flex flex-col gap-2 flex-1">
        {children}
        {description && (
          <div
            className={cn(
              "text-neutral-faded text-body-small font-semibold",
              getLineClampClassname(2)
            )}
          >
            {description}
          </div>
        )}
      </div>
      {endSlot && <div>{endSlot}</div>}
    </DropdownMenuPrimitive.Item>
  )
)

DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

// NOTE: usage explained here: https://github.com/Supernova-Studio/cloud/pull/91#issuecomment-1541800823
function DropdownMenuItemLargeContent({
  avatar,
  subtitle,
  text,
}: {
  avatar?: React.ReactNode
  subtitle?: string
  text?: string
}) {
  return (
    <div className="flex w-[250px] flex-row items-center py-8">
      <div className="mr-8">{avatar}</div>
      <div className="flex-1">
        <div className="text-caption-small text-neutral-faded font-semibold">
          {subtitle}
        </div>
        <div className="max-w-[170px] overflow-hidden text-ellipsis whitespace-nowrap">
          {text}
        </div>
      </div>
    </div>
  )
}

DropdownMenuItemLargeContent.displayName = "DropdownMenuItemLargeContent"

const DropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    checked={checked}
    className={cn(
      "focus:bg-accent focus:text-accent-foreground data-[disabled]:opacity-disabled relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors data-[disabled]:pointer-events-none",
      className
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        ✅
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
))

DropdownMenuCheckboxItem.displayName =
  DropdownMenuPrimitive.CheckboxItem.displayName

const DropdownMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.RadioItem
    ref={ref}
    className={cn(
      "focus:bg-accent focus:text-accent-foreground data-[disabled]:opacity-disabled relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors data-[disabled]:pointer-events-none",
      className
    )}
    {...props}
  >
    <span className="w-icon-medium h-icon-medium absolute left-2 flex items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <DMIcon svg={IconSettings} />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.RadioItem>
))

DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName

const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Label
    ref={ref}
    className={cn("px-x2 py-x1", inset && "pl-x4", className)}
    {...props}
  />
))

DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName

const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={cn(
      "border-b border-neutral-faded -mx-4 my-4 h-[1px]",
      className
    )}
    {...props}
  />
))

DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName

function DropdownMenuShortcut({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn("ml-auto tracking-widest opacity-60", className)}
      {...props}
    />
  )
}

DropdownMenuShortcut.displayName = "DropdownMenuShortcut"

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemLargeContent,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
}
